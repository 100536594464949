import {useTranslation} from "react-i18next";
import {Route, Routes} from "react-router-dom";
import {Title} from "../components/Title";
import {PriceList} from "./PriceList";
import Discount from "./Discount";
import {useState} from "react";
import {useAuthContext} from "../hooks/use-context/useAuthContext";
import {Tab, Tabs} from "../components/Tabs";
import GrossPriceList from "./GrossPriceList";
import MarketingPriceList from "./MarketingPriceList";

export default function ReservedConditions() {
    const {t} = useTranslation();
    const {roles, can} = useAuthContext();

    const [title, setTitle] = useState<string | null>("");

    const tabs: Tab[] = [
        {title: "Listino personalizzato", to: "/reserved-conditions", isVisibile: true},
        {title: "Tabella sconti", to: "discounts", isVisibile: !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"])},
        {title: "Listini prezzi", to: "/reserved-conditions/gross-price-list", isVisibile: !roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"])},
        {title: "Listino marketing", to: "marketing", isVisibile: can(["can_see_marketing_pricelist"])},
    ];

    return (
        <div className="py-10 px-8 2xl:px-28">
            <Title variant={Title.variant.primary}>{title}</Title>

            <Tabs tabs={tabs}/>

            <Routes>
                <Route path="/" element={<PriceList setTitle={setTitle}/>}/>
                {!roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) && (
                    <>
                        <Route path="discounts" element={<Discount setTitle={setTitle}/>}/>
                        <Route path="/gross-price-list" element={<GrossPriceList setTitle={setTitle}/>}/>
                    </>
                )}
                {can(["can_see_marketing_pricelist"]) && (
                    <Route path="marketing" element={<MarketingPriceList setTitle={setTitle}/>}/>
                )}
            </Routes>
        </div>
    );
}
