import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination, Column } from "react-table";
import { Item } from "../../../../interfaces/Item";
import { useAuthContext } from "../../../../hooks/use-context/useAuthContext";
import { UpdateProductSpecialParams, useCartItemsMutations } from "../../../../api/carts/useCartItemsMutations";
import { EditableCellQty } from "../../../commom/EditableCellQty";
import { EditableCellSc } from "../../../commom/EditableCellSc";
import { toast } from "react-toastify";

type CartTableProps = {
    cart: any,
    setIsTableValid: any,
    isTableValid: any,
    cartColumns: any,
    showMarginColumns: any
};

const CartTable: React.FC<CartTableProps> = ({ cart, cartColumns, setIsTableValid, isTableValid, showMarginColumns }) => {
    const { user, roles } = useAuthContext();

    const { t } = useTranslation();
    const { updateProduct, deleteProduct, updateProductSpecial } = useCartItemsMutations();

    // state
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

    // UPDATE ITEM
    const handleUpdateItem = async (id: string, quantity: number, discount_1: string, discount_2: string, manualNetPrice: string) => {
        let body;

        if (roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"])) {
            body = {
                quantity,
            };
        } else {
            body = {
                quantity,
                discount_1,
                discount_2,
                manualNetPrice
            };
        }

        await toast.promise(updateProduct.mutateAsync({ cart_id: cart?.data?.id, product_id: id, body: body }), {
            pending: t("Carico..."),
            success: t("Modifica effettuata con successo"),
            error: {
                render({ data }: any) {
                    if (data?.response?.status === 403) {
                        return t(data?.response?.data?.message);
                    } else {
                        return t("La modifica non è stata effettuata");
                    }
                }
            }
        });
    };

    // UPDATE DESCRIPTION
    const handleUpdateSpecial = async (params: UpdateProductSpecialParams) => {
        await toast.promise(updateProductSpecial.mutateAsync(params), {
            pending: t("Carico..."),
            success: t("Modifica effettuata con successo"),
            error: {
                render({ data }: any) {
                    if (data?.response?.status === 403) {
                        return t(data?.response?.data?.message);
                    } else {
                        return t("La modifica non è stata effettuata");
                    }
                }
            }
        });
    };

    // REMOVE ITEM
    const handleRemoveItem = useCallback(async (id: any) => {
        deleteProduct.mutateAsync({ cart_id: cart?.data?.id, product_id: id });
        setIsTableValid((prevState: any) => prevState.filter((cellId: any) => {
            return !cellId?.includes(id);
        }));
    }, [cart?.data?.id]);

    // SET HIDDEN COLUMNS
    useEffect(() => {
        if (parseInt(cart.data.kind_of_pricing) !== 1 && roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"])) {
            setHiddenColumns((prevValues) => { return [...prevValues, "discount_1", "discount_2", "pricevalue"]; });
        }

        if (cart?.data?.customer?.organization_id === "IT001") {
            setHiddenColumns((prevValues) => { return [...prevValues, "materialpriceEUR", "Margin", "Total_margin"]; });
        }

        if (!(parseInt(cart.data.kind_of_pricing) !== 1 && roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"])) && cart?.data?.customer?.organization_id !== "IT001") {
            setHiddenColumns([]);
        }
    }, [cart, showMarginColumns]);

    // TABLE DATA
    const data = useMemo(
        () => {
            const cartItems: Item[] = cart?.data?.items.filter((item: any) => {
                return item.code !== "PLATFORM" && item.code !== "PRIORITY" && item.code !== "RBCUFFIAPAL" && item.code !== "TELEPHONE" && item.code !== "AIRTRANSPORT" && item.code !== "TRANSPORT";
            }) ?? [];
            return [...cartItems].reverse();
        },
        [cart?.data?.items]
    );

    // TABLE COLUMNS
    const columns: Array<Column<Item>> = useMemo(
        () => cartColumns,
        [t, cart?.data?.items, user?.language_id, cart?.data?.total_net_price, cart?.data?.total_weight, hiddenColumns, showMarginColumns, cartColumns]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        footerGroups,
    } = useTable({ columns, data, initialState: { hiddenColumns: hiddenColumns, pageSize: 100 }, EditableCellQty, EditableCellSc, roles, setIsTableValid, isTableValid, handleUpdateItem, handleRemoveItem, handleUpdateSpecial, cart }, useSortBy, usePagination);

    return (
        <div className="overflow-x-scroll">
            <table {...getTableProps()} className="mb-8 w-full text-xs font-light border">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td
                                            {...cell.getCellProps({ className: (cell.column as any).cellClasses })}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}>
                            {group.headers.map(column => (
                                <td {...column.getFooterProps({ className: (column as any).className })}>
                                    {column.render("Footer")}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        </div>
    );
};

export default CartTable;
