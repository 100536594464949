import Select from "react-select";
import classNames from "classnames";
import useGetCustomers from "../../../api/customers/useGetCustomers";
import useSearchBarProducts from "../../../api/products/useSearchBarProducts";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { EservicesStatus } from "../../../constants/EservicesStatus";
import { Button } from "../../Button";
import { URLSearchParamsInit, useSearchParams } from "react-router-dom";
import { useAuthContext } from "../../../hooks/use-context/useAuthContext";
import { ItemSelect } from "../../commom/ItemSelect";
import { CustomerSelect } from "../../commom/CustomerSelect";
import { useComplaintTypes } from "../../../api/assistance/useComplaintTypes";
import { useSelectStyles } from "../../../hooks/useSelectStyles";

type CartSearchBarProps = {
    setSearchParams: (nextInit: URLSearchParamsInit, navigateOptions?: {
        replace?: boolean | undefined;
        state?: any;
    } | undefined) => void
};


export type ComplaintsSearchParams = {
    "filter[complaint_number]"?: string,
    "filter[item_id]"?: string,
    "filter[customer_id]"?: string,
    "filter[min_date]"?: string,
    "filter[max_date]"?: string,
    "filter[technical_sale]"?: string,
    "filter[price_classification]"?: string,
    "filter[status]"?: string,
    "filter[complaint_classification_one]"?: string,
    "sort"?: string
};

type formData = {
    "filter[complaint_number]"?: string,
    "filter[item_id]"?: string,
    "filter[customer_id]"?: string,
    "filter[min_date]"?: string,
    "filter[max_date]"?: string,
    "filter[technical_sale]"?: string,
    "filter[price_classification]"?: string,
    "filter[status]"?: string,
    "filter[complaint_classification_one]"?: string,
};

export default function ComplaintSearchBar({ setSearchParams }: CartSearchBarProps) {
    const { t } = useTranslation();
    const { roles } = useAuthContext();

    // refs
    const itemCodeSelect = useRef<any>();
    const customerSelect = useRef<any>();
    const complaintTypeSelect = useRef<any>();

    //hoks
    const [searchParams] = useSearchParams();
    const { CustomStyles } = useSelectStyles();
    const { loadItemsOptions } = useSearchBarProducts();
    const { loadCustomersOptions } = useGetCustomers();
    const { data: complaintTypes } = useComplaintTypes();

    // state
    const [formData, setFormData] = useState<formData>({});
    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);


    const handleInputsChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (value.length > 0) {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, [name]: value };
            });
        } else {
            const newForm = { ...formData };
            delete newForm[name as keyof formData];
            setFormData(newForm);
        }
    };

    // ITEM CODE
    const handleItemSearchChange = (inputValue: any, action: any) => {
        console.log(inputValue);

        if (action?.action === "select-option") {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, [action?.name]: inputValue?.value?.id };
            });
        } else {
            const newForm = { ...formData };
            delete newForm[action?.name as keyof formData];
            setFormData(newForm);
        }
    };

    // CLIENT
    const handleClientSearchChange = (inputValue: any, action: any) => {
        setSelectedCustomer(inputValue);
        if (action?.action === "select-option") {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, "filter[customer_id]": inputValue?.code };
            });
        } else {
            const newForm = { ...formData };
            delete newForm["filter[customer_id]"];
            setFormData(newForm);
        }
    };

    // CLIENT
    const handleComplaintTypeChange = (inputValue: any, action: any) => {
        if (action?.action === "select-option") {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, "filter[complaint_classification_one]": inputValue?.id };
            });
        } else {
            const newForm = { ...formData };
            delete newForm["filter[complaint_classification_one]"];
            setFormData(newForm);
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        // return to pagination 1 when the user uses a filter
        const updatedParams = { ...formData, page: "1" };
        setSearchParams(updatedParams);
    };

    const resetForm = () => {
        itemCodeSelect.current.clearValue();
        customerSelect.current.clearValue();
        complaintTypeSelect.current.clearValue();
        setFormData({});
        setSearchParams({});
    };

    useEffect(() => {
        const tmpParams: any = {};

        searchParams.forEach((value: any, key: any) => {
            tmpParams[key] = value;
        });

        setFormData(tmpParams);
    }, [searchParams]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <fieldset className="m-0">
                    <legend className="sr-only"><span>{t("Ricerca")}</span></legend>

                    <div className="space-y-6">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 whitespace-nowrap">
                            <div>
                                <label>{t("Numero reclamo")}</label>
                                <input
                                    value={formData["filter[complaint_number]"] ?? ""}
                                    type="text"
                                    name="filter[complaint_number]"
                                    onChange={e => handleInputsChange(e)}
                                    placeholder={t("Inserisci numero reclamo")}
                                />
                            </div>
                            <div>
                                <label>{t("Codice articolo")}</label>
                                <ItemSelect
                                    ref={itemCodeSelect}
                                    name="filter[item_id]"
                                    loadItemsOptions={loadItemsOptions}
                                    handleChange={handleItemSearchChange}
                                />
                            </div>
                            <div>
                                <label>{t("Gruppo prodotto")}</label>
                                <input
                                    type="text"
                                    value={formData["filter[price_classification]"] ?? ""}
                                    name="filter[price_classification]"
                                    onChange={e => handleInputsChange(e)}
                                    placeholder={t("Inserisci gruppo prodotto")} />
                            </div>
                        </div>
                        <div className={classNames("grid grid-cols-1 gap-4 whitespace-nowrap", {
                            "md:grid-cols-2": !roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]),
                            "md:grid-cols-1": roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]),
                        })}>
                            {/* COMPLAINT TYPE */}
                            <div>
                                <label>{t("Tipo errore")}</label>
                                <Select
                                    ref={complaintTypeSelect}
                                    options={complaintTypes}
                                    styles={CustomStyles}
                                    placeholder={t("Seleziona il problema riscontrato")}
                                    loadingMessage={() => t("Caricamento in corso...")}
                                    noOptionsMessage={() => t("Nessun risultato")}
                                    onChange={handleComplaintTypeChange}
                                    isClearable
                                />
                            </div>

                            {/* TS CODE OR NAME */}
                            {!roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]) && <div>
                                <label>{t("Technical Salesman")}</label>
                                <input
                                    value={formData["filter[technical_sale]"] ?? ""}
                                    type="text"
                                    name="filter[technical_sale]"
                                    onChange={e => handleInputsChange(e)}
                                    placeholder={t("inserisci technical salesman")}
                                />
                            </div>}
                        </div>

                        <div className={classNames("grid grid-cols-1 gap-4 whitespace-nowrap", {
                            "md:grid-cols-4": !roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]),
                            "md:grid-cols-3": roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]),
                        })}>
                            <CustomerSelect
                                label="Seleziona cliente"
                                ref={customerSelect}
                                loadCustomersOptions={loadCustomersOptions}
                                handleChange={handleClientSearchChange}
                                selectedCustomer={selectedCustomer}
                                setSelectedCustomer={setSelectedCustomer}
                            />

                            <div>
                                <label>{t("Da")}</label>
                                <input
                                    value={formData["filter[min_date]"] ?? ""}
                                    type="date"
                                    name="filter[min_date]"
                                    onChange={e => handleInputsChange(e)}
                                />
                            </div>
                            <div>
                                <label>{t("A")}</label>
                                <input
                                    value={formData["filter[max_date]"] ?? ""}
                                    type="date"
                                    name="filter[max_date]"
                                    onChange={e => handleInputsChange(e)}
                                />
                            </div>
                            <div>
                                <label>{t("Stato")}</label>
                                <select
                                    className="h-11"
                                    value={formData["filter[status]"] ?? ""}
                                    name="filter[status]"
                                    onChange={e => handleInputsChange(e)}
                                >
                                    <option value="">{t("Tutti")}</option>
                                    {
                                        EservicesStatus.complaints.map((eserviceStatus) => (
                                            <option key={eserviceStatus.status} value={eserviceStatus.status}>{t(eserviceStatus.description)}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="w-1/3 flex gap-x-2">
                            <Button variant={Button.variant.primary} size={Button.size.full}>{t("Cerca")}</Button>
                            <Button
                                variant={Button.variant.text}
                                className="text-xs py-0 flex-1 whitespace-nowrap"
                                icon={Button.icon.reset}
                                onClick={resetForm}
                            >
                                {t("reset ricerca")}
                            </Button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </>
    );
}
