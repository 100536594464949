import classNames from "classnames";
import { useTranslation } from "react-i18next";
import ErrorIcon from "../components/ErrorIcon";
import { EditableCellQty } from "../components/commom/EditableCellQty";
import { AvailabilityCell } from "../components/commom/AvailabilityCell";
import { EditableCellSc } from "../components/commom/EditableCellSc";
import useMarginCalculationMethods from "./useMarginCalculationMethods";
import { EditableCellNetPrice } from "../components/cart/step_1/cart-table/EditableCellNetPrice";
import { Modal } from "../components/commom/Modal";
import { Button } from "../components/Button";
import { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { useAuthContext } from "./use-context/useAuthContext";
import EditableCellDescription from "../components/commom/EditableCellDescription";

const useCartOfferColumns = (container: any, showMarginColumns: boolean) => {
    const { t } = useTranslation();
    const { can } = useAuthContext();

    const { calculateNetPrice, getCurrencyDecimals, semiramisRound } = useMarginCalculationMethods();

    if (showMarginColumns && can(["can_see_margin_calculator"])) {
        // MARGIN COLUMNS
        return [
            {
                Header: container?.data?.customer?.organization_id,
                className: "p-4 bg-tertiary border-b border-gray-200",
                columns: [
                    {
                        Header: t("Codice articolo").toString(),
                        accessor: "code",
                        className: "text-center p-4 bg-tertiary font-normal",
                        cellClasses: "text-center p-4",
                        Cell: (props: any) => {
                            return (
                                <div className="flex gap-1 items-center">
                                    <div>
                                        <div className='mx-auto mb-1 text-center text-2xl'>
                                            <img src={`https://resources.rothoblaas.com/eservice-images/product/${props.cell.row.original.code}`} className="hover:shadow hover:scale-500 ease-in-out duration-300 max-w-[50px] max-h-[50px] m-auto" alt='' />
                                        </div>
                                        {
                                            props.row.original.isinpricelistrb ?
                                                <span className={classNames("block text-center hover:underline", { "text-rose-500": !!props.row.original.is_invalid })}>
                                                    <a href={`https://www.rothoblaas.com/search?q=${props.cell.row.original.code}&s=eservice`} target="_blank" rel="noreferrer">{props.cell.row.original.code.toUpperCase()}</a>
                                                </span>
                                                :
                                                <span className="block text-center hover:underline">{props.cell.row.original.code}</span>
                                        }
                                    </div>
                                    {!!props.row.original.is_invalid && <ErrorIcon>{t("Articolo non valido")}</ErrorIcon>}
                                </div>
                            );
                        }
                    },
                    {
                        Header: t("Descrizione").toString(),
                        accessor: "description",
                        className: "text-left p-4 bg-tertiary font-normal",
                        cellClasses: "p-4",
                        Cell: EditableCellDescription
                    },
                    {
                        Header: t("Quantità").toString(),
                        accessor: "qty",
                        className: "text-left p-4 bg-tertiary font-normal",
                        cellClasses: "p-4",
                        Cell: EditableCellQty,
                    },
                    {
                        Header: t("Disponibilità").toString(),
                        className: "text-left p-4 bg-tertiary font-normal",
                        cellClasses: "p-4",
                        Cell: AvailabilityCell,
                    },
                    {
                        Header: t("Sconto %").toString(),
                        accessor: "discount_1",
                        disableSortBy: true,
                        className: "text-left p-4 bg-tertiary font-normal",
                        cellClasses: "p-4",
                        Cell: EditableCellSc,
                    },
                    {
                        Header: t("Sconto 2 %").toString(),
                        accessor: "discount_2",
                        disableSortBy: true,
                        className: "text-left p-4 bg-tertiary font-normal",
                        cellClasses: "p-4",
                        Cell: EditableCellSc,
                    },
                    {
                        Header: t("Margine %").toString(),
                        accessor: "margine_netto",
                        className: "text-right p-4 bg-tertiary font-normal",
                        cellClasses: "text-right p-4 bg-tertiary",
                        Cell: (props: any) => {
                            if (!props.row.original.purchaseprice) return "-";

                            const qty = props.cell.row.original.qty;
                            const discount_1 = props.cell.row.original.discount_1;
                            const discount_2 = props.cell.row.original.discount_2;
                            const price = props.cell.row.original.pricevalue;
                            const purchaseprice = props.cell.row.original.purchaseprice;

                            const netPrice = calculateNetPrice(price, qty, discount_1, discount_2, getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null));
                            const prezzoAcquisto = parseFloat(purchaseprice);

                            const totAcquisto = prezzoAcquisto * qty;
                            const totVendita = netPrice * qty;

                            const margine = semiramisRound((totVendita - totAcquisto) / totVendita * 100, 2);

                            const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                            const result = numberFormat.format(margine);

                            return (
                                <>
                                    {<span>{result}</span>}
                                </>
                            );
                        },
                        Footer: (info: any) => {
                            const data = info.data;
                            let sumTotAcquisto = 0;
                            let sumTotVendita = 0;

                            for (let i = 0; i < data.length; i++) {
                                if (data[i].purchaseprice) {
                                    const qty = data[i].qty;
                                    const discount_1 = data[i].discount_1;
                                    const discount_2 = data[i].discount_2;
                                    const price = data[i].pricevalue;
                                    const purchaseprice = data[i].purchaseprice;

                                    const netPrice = calculateNetPrice(price, qty, discount_1, discount_2, getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null));
                                    const prezzoAcquisto = parseFloat(purchaseprice);

                                    sumTotAcquisto += prezzoAcquisto * qty;
                                    sumTotVendita += netPrice * qty;
                                }
                            }

                            const margine = semiramisRound((sumTotVendita - sumTotAcquisto) / sumTotVendita * 100, 2);

                            if (isNaN(margine)) {
                                return "-";
                            } else {
                                const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                                const result = numberFormat.format(margine);
                                return result;
                            }
                        }
                    },
                    {
                        Header: t("Margine ({{currency}})", { currency: container?.data?.items?.length ? container?.data?.items[0].currency : null }).toString(),
                        className: "text-right p-4 bg-tertiary font-normal",
                        cellClasses: "text-right p-4 bg-tertiary",
                        Cell: (props: any) => {
                            if (!props.row.original.purchaseprice) return "-";

                            const qty = props.cell.row.original.qty;
                            const discount_1 = props.cell.row.original.discount_1;
                            const discount_2 = props.cell.row.original.discount_2;
                            const price = props.cell.row.original.pricevalue;
                            const netPrice = calculateNetPrice(price, qty, discount_1, discount_2, getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null));
                            const totVendita = netPrice * qty;

                            const prezzoAcquisto = parseFloat(props.cell.row.original.purchaseprice);

                            const calc = semiramisRound(totVendita - (prezzoAcquisto * qty), getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null));

                            const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null), maximumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null) });
                            const result = numberFormat.format(calc);

                            return (
                                <>
                                    {<span>{result}</span>}
                                </>
                            );
                        },
                        Footer: (info: any) => {
                            const data = info.data;

                            let sumCalc = 0;
                            for (let i = 0; i < data.length; i++) {
                                if (data[i].purchaseprice) {
                                    const qty = data[i].qty;
                                    const discount_1 = data[i].discount_1;
                                    const discount_2 = data[i].discount_2;
                                    const price = data[i].pricevalue;
                                    const netPrice = calculateNetPrice(price, qty, discount_1, discount_2, getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null));
                                    const totVendita = netPrice * qty;

                                    const prezzoAcquisto = parseFloat(data[i].purchaseprice);

                                    const calc = semiramisRound(totVendita - (prezzoAcquisto * qty), getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null));

                                    sumCalc += calc;
                                }
                            }

                            const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null), maximumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null) });
                            const result = numberFormat.format(sumCalc);

                            return result;
                        }
                    },
                    {
                        Header: t("Prezzo listino cliente ({{currency}})", { currency: container?.data?.items?.length ? container?.data?.items[0].currency : "-" }).toString(),
                        accessor: "pricevalue",
                        disableSortBy: true,
                        className: "text-right p-4 bg-tertiary font-normal",
                        cellClasses: "text-right p-4",
                        Cell: (props: any) => {
                            let price = props.cell.row.original.pricevalue;

                            const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: 5 });

                            if (price) {
                                price = semiramisRound(parseFloat(price.toString()), 5);
                            }

                            return (
                                price ? <span>{numberFormat.format(price)}</span> : <span>-</span>
                            );
                        }
                    },
                    {
                        Header: t("Prezzo scontato cliente unitario ({{currency}})", { currency: container?.data?.items?.length ? container?.data?.items[0].currency : "-" }).toString(),
                        accessor: "net_price",
                        className: "text-right p-4 bg-tertiary font-normal",
                        cellClasses: "text-right p-4",
                        Cell: EditableCellNetPrice
                    },
                    {
                        Header: t("Totale scontato ({{currency}})", { currency: container?.data?.items?.length ? container?.data?.items[0].currency : "-" }).toString(),
                        accessor: "totale_vendita",
                        className: "text-right p-4 bg-tertiary font-normal",
                        cellClasses: "text-right p-4 bg-tertiary font-bold",
                        Cell: (props: any) => {
                            const qty = props.cell.row.original.qty;

                            const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null), maximumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null) });
                            const totVendita = semiramisRound(props.row.original.net_price * qty, getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null));

                            return (
                                <>
                                    <span>{numberFormat.format(totVendita)}</span>
                                </>
                            );
                        },
                        Footer: () => {
                            const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null), maximumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null) });
                            const result = numberFormat.format(container?.data?.total_net_price);
                            return result;
                        }
                    },
                    {
                        Header: () => {
                            // console.log(t('Prezzo acquisto info').toString());
                            // console.log(t('Prezzo acquisto info').toString().split('\\n').join('#').split("\n").join('#').split('#'));
                            return <>
                                <>{t("Prezzo acquisto ({{currency}})", { currency: container?.data?.items?.length ? container?.data?.items[0].currency : null }).toString()}</>
                                <div className="font-bold mt-2 group relative block py-0 px-0 underline duration-300">
                                    Info
                                    <div className="absolute hidden group-hover:block -top-2 -right-3 translate-x-full w-56 px-4 py-4 bg-gray-700 rounded-lg text-left text-white text-sm">
                                        <div className="flex-none">{t("Prezzo acquisto info").toString().split("\\n").join("#").split("\n").join("#").split("#").map((line, i) => (
                                            <span className="mb-3 block" key={i}>{line}</span>
                                        ))}</div>
                                    </div>
                                </div>
                            </>;
                        },
                        accessor: "purchaseprice",
                        className: "text-right p-4 bg-tertiary font-normal",
                        cellClasses: "text-right p-4",
                        Cell: (props: any) => {
                            if (!props.row.original.purchaseprice) return "-";

                            let prezzoAcquisto = parseFloat(props.cell.row.original.purchaseprice);
                            prezzoAcquisto = semiramisRound(prezzoAcquisto, 5);

                            const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: 5 });
                            const result = numberFormat.format(prezzoAcquisto);
                            return result;
                        }
                    },
                    {
                        Header: t("Peso netto unitario kg").toString(),
                        accessor: "netweight",
                        className: "text-right p-4 bg-tertiary font-normal",
                        cellClasses: "text-right p-4",
                        Cell: (props: any) => {
                            const netWeight = semiramisRound(parseFloat(props.cell.row.original.netweight), 4);

                            const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: 4 });
                            const result = numberFormat.format(netWeight);
                            return result;
                        },
                    },
                    {
                        Header: t("Peso netto totale kg").toString(),
                        className: "text-right p-4 bg-tertiary font-normal",
                        cellClasses: "text-right p-4",
                        Cell: (props: any) => {
                            const qty = props.cell.row.original.qty;
                            const netWeight = parseFloat(props.cell.row.original.netweight);
                            const totRowWeight = semiramisRound(qty * netWeight, 4);

                            const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: 4 });
                            const result = numberFormat.format(totRowWeight);

                            return result;
                        },
                        Footer: () => {
                            const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: 4 });
                            const result = numberFormat.format(container?.data?.total_weight);

                            return result;
                        }
                    }
                ],
                Footer: ""
            },
            {
                Header: "IT001",
                id: "second_header",
                className: "p-4 bg-table-secondary border-b border-white",
                columns: [
                    {
                        Header: t("Prezzo netto di vendita IT-SUB ({{currency}})", { currency: "EUR" }).toString(),
                        accessor: "materialpriceEUR",
                        className: "text-right p-4 bg-table-secondary font-normal",
                        cellClasses: "text-right p-4",
                        Cell: (props: any) => {
                            if (!props.row.original.materialpriceEUR) return "-";

                            const material_price_eur = parseFloat(props.row.original.materialpriceEUR);

                            const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: 5 });
                            const result = numberFormat.format(material_price_eur);

                            return (
                                <>
                                    <span>{result}</span>
                                </>
                            );
                        }
                    },
                    {
                        Header: t("Margine IT-SUB %").toString(),
                        id: "Margin",
                        className: "text-right p-4 bg-table-secondary font-normal",
                        cellClasses: "text-right bg-table-secondary p-4",
                        Cell: (props: any) => {
                            if (!props.row.original.materialpriceEUR) return "-";

                            const material_price_eur = parseFloat(props.row.original.materialpriceEUR);
                            const purchase_price_hq = parseFloat(props.row.original.purchaseprice_hq);

                            const calc = semiramisRound(((material_price_eur - purchase_price_hq) / material_price_eur) * 100, 2);

                            const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                            const result = numberFormat.format(calc);

                            return (
                                <>
                                    {<span>{result}</span>}
                                </>
                            );
                        },
                        Footer: (info: any) => {
                            const data = info.data;
                            let sum_material_price_eur = 0;
                            let sum_purchase_price_hq = 0;

                            for (let i = 0; i < data.length; i++) {
                                if (data[i].materialpriceEUR) {
                                    const material_price_eur = parseFloat(data[i].materialpriceEUR);
                                    const purchase_price_hq = parseFloat(data[i].purchaseprice_hq);

                                    sum_material_price_eur += material_price_eur;
                                    sum_purchase_price_hq += purchase_price_hq;
                                }
                            }

                            const calc = semiramisRound(((sum_material_price_eur - sum_purchase_price_hq) / sum_material_price_eur) * 100, 2);

                            if (isNaN(calc)) {
                                return "-";
                            } else {
                                const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                                const result = numberFormat.format(calc);

                                return result;
                            }
                        }
                    },
                    {
                        Header: t("Margine totale IT-SUB ({{currency}})", { currency: "EUR" }).toString(),
                        id: "Total_margin",
                        className: "text-right p-4 bg-table-secondary font-normal",
                        cellClasses: "text-right p-4 bg-table-secondary",
                        Cell: (props: any) => {
                            if (!props.row.original.materialpriceEUR) return "-";

                            const qty = props.row.original.qty;
                            const material_price_eur = parseFloat(props.row.original.materialpriceEUR);
                            const purchase_price_hq = parseFloat(props.row.original.purchaseprice_hq);

                            const calc = semiramisRound((material_price_eur - purchase_price_hq) * qty, getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null));

                            const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null), maximumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null) });
                            const result = numberFormat.format(calc);

                            return (
                                <>
                                    {<span>{result}</span>}
                                </>
                            );
                        },
                        Footer: (info: any) => {
                            const data = info.data;
                            let sumCalc = 0;

                            for (let i = 0; i < data.length; i++) {
                                if (data[i].materialpriceEUR) {
                                    const qty = data[i].qty;
                                    const material_price_eur = parseFloat(data[i].materialpriceEUR);
                                    const purchase_price_hq = parseFloat(data[i].purchaseprice_hq);

                                    const calc = semiramisRound((material_price_eur - purchase_price_hq) * qty, getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null));
                                    sumCalc += calc;
                                }
                            }

                            const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null), maximumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null) });
                            const result = numberFormat.format(sumCalc);

                            return result;
                        }
                    },
                ],
                Footer: ""
            },
            {
                Header: " ",
                className: "bg-tertiary border-b border-gray-200",
                columns: [
                    {
                        Header: " ",
                        className: "text-left p-4 bg-tertiary",
                        cellClasses: "text-center p-4",
                        Cell: (props: any) => {
                            const { id } = props.row.original;

                            const [open, setOpen] = useState(false);

                            return (
                                <>
                                    {
                                        open &&
                                        <Modal title={t("Elimina prodotto")} open={open} onClose={setOpen}>
                                            <p>{t("Elimina prodotto dalla lista?")}</p>
                                            <div className="text-right space-x-2 mt-4">
                                                <Button variant={Button.variant.text} onClick={() => setOpen(false)}>{t("Annulla")}</Button>
                                                <Button variant={Button.variant.primary} onClick={() => props.handleRemoveItem(id)}>{t("Procedi")}</Button>
                                            </div>
                                        </Modal>
                                    }


                                    <FaTrash
                                        className="cursor-pointer hover:text-secondary"
                                        onClick={() => setOpen(true)}
                                    />
                                </>
                            );
                        }
                    }
                ],
                Footer: ""
            }
        ];
    } else {
        // NORMAL COLUMNS
        return [
            {
                Header: t("Codice articolo").toString(),
                accessor: "code",
                className: "text-left p-4 bg-tertiary bg-tertiary font-normal",
                cellClasses: "text-left p-4",
                Cell: (props: any) => {
                    return (
                        <div className="flex justify-start">
                            <div>
                                <div className='mx-auto mb-1 text-center text-2xl'>
                                    <img src={`https://resources.rothoblaas.com/eservice-images/product/${props.cell.row.original.code}`} className="hover:shadow hover:scale-500 ease-in-out duration-300 max-w-[50px] max-h-[50px] m-auto" alt='' />
                                </div>
                                {
                                    props.row.original.isinpricelistrb ?
                                        <span className={classNames("block text-center hover:underline", { "text-rose-500": !!props.row.original.is_invalid })}>
                                            <a href={`https://www.rothoblaas.com/search?q=${props.cell.row.original.code}&s=eservice`} target="_blank" rel="noreferrer">{props.cell.row.original.code.toUpperCase()}</a>
                                        </span>
                                        :
                                        <span className="block text-center">{props.cell.row.original.code}</span>
                                }
                            </div>
                            {!!props.row.original.is_invalid && <ErrorIcon>{t("Articolo non valido")}</ErrorIcon>}
                        </div>
                    );
                }
            },
            {
                Header: t("Descrizione").toString(),
                accessor: "description",
                className: "text-left p-4 bg-tertiary bg-tertiary font-normal",
                cellClasses: "text-left p-4",
                Cell: EditableCellDescription
            },
            {
                Header: t("Quantità").toString(),
                accessor: "qty",
                className: "text-left p-4 bg-tertiary font-normal",
                cellClasses: "text-left p-4",
                Cell: EditableCellQty,
            },
            {
                Header: t("Disponibilità").toString(),
                className: "text-left p-4 bg-tertiary font-normal",
                cellClasses: "text-left p-4",
                Cell: AvailabilityCell,
            },
            {
                Header: t("Sconto %").toString(),
                accessor: "discount_1",
                disableSortBy: true,
                className: "text-left p-4 bg-tertiary font-normal",
                cellClasses: "text-left p-4",
                Cell: EditableCellSc,
            },
            {
                Header: t("Sconto 2 %").toString(),
                accessor: "discount_2",
                disableSortBy: true,
                className: "text-left p-4 bg-tertiary font-normal",
                cellClasses: "text-left p-4",
                Cell: EditableCellSc,
            },
            {
                Header: t("Prezzo listino cliente ({{currency}})", { currency: container?.data?.items?.length ? container?.data?.items[0].currency : "-" }).toString(),
                accessor: "pricevalue",
                disableSortBy: true,
                className: "text-right p-4 bg-tertiary font-normal",
                cellClasses: "text-right p-4",
                Cell: (props: any) => {
                    let price = props.cell.row.original.pricevalue;

                    const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: 5 });

                    if (price) {
                        price = semiramisRound(parseFloat(price.toString()), 5);
                    }

                    return (
                        price ? <span>{numberFormat.format(price)}</span> : <span>-</span>
                    );
                }
            },
            {
                Header: t("Prezzo scontato cliente unitario ({{currency}})", { currency: container?.data?.items?.length ? container?.data?.items[0].currency : "-" }).toString(),
                accessor: "net_price",
                className: "text-right p-4 bg-tertiary font-normal",
                cellClasses: "text-right p-4",
                Cell: EditableCellNetPrice
            },
            {
                Header: t("Totale scontato ({{currency}})", { currency: container?.data?.items?.length ? container?.data?.items[0].currency : "-" }).toString(),
                accessor: "totale_vendita",
                className: "text-right p-4 bg-tertiary font-normal",
                cellClasses: "text-right p-4 bg-tertiary font-bold",
                Cell: (props: any) => {
                    const qty = props.cell.row.original.qty;

                    const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null), maximumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null) });
                    const totVendita = semiramisRound(props.row.original.net_price * qty, getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null));

                    return (
                        <>
                            <span>{numberFormat.format(totVendita)}</span>
                        </>
                    );
                },
                Footer: () => {
                    const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null), maximumFractionDigits: getCurrencyDecimals(container?.data?.items?.length ? container?.data?.items[0].currency : null) });
                    const result = numberFormat.format(container?.data?.total_net_price);
                    return result;
                }
            },
            {
                Header: t("Peso netto unitario kg").toString(),
                accessor: "netweight",
                className: "text-right p-4 bg-tertiary font-normal",
                cellClasses: "text-right p-4",
                Cell: (props: any) => {
                    const netWeight = semiramisRound(parseFloat(props.cell.row.original.netweight), 4);

                    const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: 4 });
                    const result = numberFormat.format(netWeight);
                    return result;
                },
            },
            {
                Header: t("Peso netto totale kg").toString(),
                className: "text-right p-4 bg-tertiary font-normal",
                cellClasses: "text-right p-4",
                Cell: (props: any) => {
                    const qty = props.cell.row.original.qty;
                    const netWeight = parseFloat(props.cell.row.original.netweight);
                    const totRowWeight = semiramisRound(qty * netWeight, 4);

                    const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: 4 });
                    const result = numberFormat.format(totRowWeight);

                    return result;
                },
                Footer: () => {
                    const numberFormat = new Intl.NumberFormat(container?.data?.customer?.language_id, { minimumFractionDigits: 4 });
                    const result = numberFormat.format(container?.data?.total_weight);

                    return result;
                }
            },
            {
                Header: " ",
                className: "text-right p-4 bg-tertiary font-normal",
                cellClasses: "text-right p-4",
                Cell: (props: any) => {
                    const { id } = props.row.original;

                    const [open, setOpen] = useState(false);

                    return (
                        <>
                            {
                                open &&
                                <Modal title={t("Elimina prodotto")} open={open} onClose={setOpen}>
                                    <p>{t("Elimina prodotto dalla lista?")}</p>
                                    <div className="text-right space-x-2 mt-4">
                                        <Button variant={Button.variant.text} onClick={() => setOpen(false)}>{t("Annulla")}</Button>
                                        <Button variant={Button.variant.primary} onClick={() => props.handleRemoveItem(id)}>{t("Procedi")}</Button>
                                    </div>
                                </Modal>
                            }


                            <FaTrash
                                className="cursor-pointer hover:text-secondary"
                                onClick={() => setOpen(true)}
                            />
                        </>
                    );
                }
            }
        ];
    }
};

export default useCartOfferColumns;
