import { useQueryClient } from "@tanstack/react-query";
import { formatDate } from "date-fns/format";
import { useTranslation } from "react-i18next";
import client from "../api/client";
import { Button } from "../components/Button";
import { useEffect, useState } from "react";
import Pagination from "../components/Pagination";
import { PriceListTable } from "../components/reserved-conditions/price-list/PriceListTable";
import useGetCustomers from "../api/customers/useGetCustomers";
import { downloadResource } from "../constants/DownloadResource";
import { CustomerSelect } from "../components/commom/CustomerSelect";
import usePriceList from "../api/reserved-conditions/price-list/usePriceList";
import PriceListSearchBar from "../components/reserved-conditions/price-list/PriceListSearchBar";
import { ImFilesEmpty } from "react-icons/im";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import { Loader } from "../components/Loader";
import usePricelistDownloadInfo from "../api/reserved-conditions/price-list/usePricelistDownloadInfo";
import { Slide, ToastContainer } from "react-toastify";

// TODO: update filters
type searchParams = {
    "filter[item_code]"?: string,
    "filter[customer_code]"?: any,
    "filter[search]"?: string,
    "sort"?: string
};

export const PriceList = ({ setTitle }: any) => {
    const { t, i18n } = useTranslation();
    const { roles } = useAuthContext();
    const queryClient = useQueryClient();

    // state
    const [currentPage, setCurrentPage] = useState(1);
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [searchParams, setSearchParams] = useState<searchParams>({});
    const [customerCode, setCustomerCode] = useState<string | null>(null);
    const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
    const [isNetPrice, setIsNetPrice] = useState<boolean>(selectedCustomer?.kind_of_pricing === "2");
    const [xslxGenerationTimeout, setXslxGenerationTimeout] = useState<any>(false);
    const [generationDate, setGenerationDate] = useState<any>(null);
    const [disableGenerationUntilUpdate, setDisableGenerationUntilUpdate] = useState<any>(false);

    // hooks
    const { loadCustomersOptions } = useGetCustomers();

    useEffect(() => {
        setTitle(t("Condizioni riservate"));
    }, [i18n.language]);

    // reset regenrate button
    useEffect(() => {
        setDisableGenerationUntilUpdate(false);
    }, [loadCustomersOptions]);

    // react query
    const {
        priceList,
        isFetching,
        isLoading
    } = usePriceList({
        "page": currentPage,
        "filter[customer_code]": customerCode, ...searchParams
    });
    const {
        pricelistCustomer,
        pricelistCustomerIsFetching
    } = usePricelistDownloadInfo(customerCode, (isFetching || isLoading));

    useEffect(() => {
        if (pricelistCustomer?.data?.invoice_pricelistdate) {
            setGenerationDate(null);
        } else {
            setGenerationDate(formatDate(new Date(), "yyyy-MM-dd"));
        }
    }, [pricelistCustomer]);

    // handle customer SELECT change
    const handleCustomerChange = (inputValue: any) => {
        setSelectedCustomer(inputValue);
        // set form value customer code
        if (inputValue) {
            setSelectedCustomer(inputValue);
        } else {
            setSelectedCustomer(null);
        }
        // reset generation disabled
        setDisableGenerationUntilUpdate(false);
    };

    const generatePriceListXlsx = async (ev: any) => {
        ev.preventDefault();

        // avoid multiple
        if (isGenerating) {
            return;
        }

        setIsGenerating(true);

        const res = await client.get("/reserved-conditions/price-list/generate", {
            params: {
                "filter[customer_code]": customerCode,
                "generation_date": generationDate
            }
        });

        // reset generation
        setDisableGenerationUntilUpdate(true);
        setGenerationDate(null);

        // refetch data
        queryClient.invalidateQueries({
            queryKey: ["price-list-customer", customerCode],
            exact: true,
        });

        setIsGenerating(false);
    };

    // downlaod method
    const downloadPriceListXlsx = async (ev: any) => {
        ev.preventDefault();

        // avoid multiple
        if (isDownloadLoading) {
            return;
        }

        setIsDownloadLoading(true);

        let params: any = {
            "filter[customer_code]": customerCode
        };
        if (!roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"])) {
            params["kind_of_pricing"] = isNetPrice ? "2" : "1";
        }

        const res = await client.get("/reserved-conditions/price-list/download", {
            params: params,
            headers: {
                responseType: "blob"
            }
        });

        // mode download
        if (res.data && res.data.content && res.data.name && res.data.type) {
            const filetype = res.data.type;
            const content = res.data.content;
            const filename = res.data.name;

            downloadResource(content, filename, filetype);
        }

        setIsDownloadLoading(false);
    };

    useEffect(() => {
        setCustomerCode(selectedCustomer?.code ?? null);
        // set of net price flag
        setIsNetPrice(selectedCustomer?.kind_of_pricing === "2");
        setCurrentPage(1);
    }, [selectedCustomer]);

    useEffect(() => {
        if (
            !pricelistCustomer?.data?.pricelist_generation_in_progress &&
            (!pricelistCustomer?.data?.pricelist_download_path || pricelistCustomer?.data?.pricelist_download_path.length <= 0)
        ) {
            setXslxGenerationTimeout(pricelistCustomer?.data?.pricelist_minutes_since_last_generation > 60);
        } else {
            setXslxGenerationTimeout(false);
        }
    }, [pricelistCustomerIsFetching]);

    // reset page when params change
    useEffect(() => {
        setCurrentPage(1);
    }, [searchParams]);

    const generationInProgress = pricelistCustomer?.data?.pricelist_generation_in_progress;

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                transition={Slide}
            />

            {(isFetching) &&
                <Loader />}

            {(isDownloadLoading || isGenerating) &&
                <Loader />}

            <div className="border bg-neutral-weaker p-6 my-10">
                <fieldset className="w-full md:w-1/2 space-y-6">
                    <div>
                        <legend className="sr-only">{t("Cliente")}</legend>
                        <CustomerSelect
                            label="Seleziona cliente"
                            loadCustomersOptions={loadCustomersOptions}
                            handleChange={handleCustomerChange}
                            error={priceList?.no_brand && (selectedCustomer && !isFetching) ? { message: !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"]) ? t("Net price non disponibile per mancanza del MARCHIO in anagrafica cliente – contattare la propria referente CS") : t("Net price non disponibile per mancanza del MARCHIO in anagrafica cliente – contattare il proprio TS") } : undefined}
                            selectedCustomer={selectedCustomer}
                            setSelectedCustomer={setSelectedCustomer}
                        />
                    </div>
                    {
                        (priceList?.data?.data?.length > 0 || priceList?.data?.length <= 0) &&
                        <PriceListSearchBar
                            setSearchParams={setSearchParams}
                            selectedCustomer={selectedCustomer} />
                    }
                    {
                        (selectedCustomer && !roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"])) &&
                        <div className="flex items-center gap-x-1 font-light">
                            <input
                                id="kindofpricing_0"
                                name="kindofpricing"
                                type="radio"
                                checked={isNetPrice}
                                className="cursor-pointer accent-primary"
                                onChange={() => setIsNetPrice(true)}
                            />
                            <label htmlFor="kindofpricing_0" className="cursor-pointer p-0">{t("Prezzo netto")}</label>
                            &nbsp;
                            <input
                                id="kindofpricing_1"
                                name="kindofpricing"
                                type="radio"
                                checked={!isNetPrice}
                                className="cursor-pointer accent-primary"
                                onChange={() => setIsNetPrice(false)}
                            />
                            <label htmlFor="kindofpricing_1" className="cursor-pointer p-0">{t("Prezzo lordo con sconti")}</label>
                        </div>
                    }
                </fieldset>
            </div>

            {priceList?.data?.data?.length > 0 && priceList?.customer?.processing_tables?.length <= 0 &&
                <>
                    <PriceListTable
                        showNetPrice={isNetPrice}
                        items={priceList.data.data}
                        customer={priceList.customer}
                        setSearchParams={setSearchParams}
                        selectedCustomer={selectedCustomer}
                    />

                    <Pagination
                        currentPage={currentPage}
                        totalCount={priceList.data.total}
                        pageSize={10}
                        onPageChange={setCurrentPage}
                    />

                    <div
                        className="text-right space-x-2 mt-4">
                        {(isDownloadLoading || isGenerating) &&
                            <Loader />}

                        {
                            pricelistCustomer?.data?.invoice_pricelistdate &&
                            <div
                                className={"mt-8 mb-4"}>{t("Listino clienti personalizzato con condizioni contrattuali, per avere il listino personalizzato aggiornato seleziona una data ad oggi o nel futuro.")}</div>
                        }

                        <div
                            className={"flex gap-4 items-center justify-end"}>
                            {
                                !pricelistCustomer?.data?.invoice_pricelistdate &&
                                <label
                                    htmlFor={"generationDate"}>{t("Listino personalizzato valido in data")}</label>
                            }
                            {
                                pricelistCustomer?.data?.invoice_pricelistdate &&
                                <label
                                    htmlFor={"generationDate"}>{t("Scegli data di generazione listino prezzi")}</label>
                            }

                            <input
                                className={"w-auto"}
                                onChange={(e) => {
                                    const d = e.target.value;
                                    setGenerationDate(d && d.length === 10 ? d : null);
                                }}
                                value={generationDate ?? ""}
                                id="generationDate"
                                type="date"
                                disabled={generationInProgress}
                            />

                            <Button
                                icon={Button.icon.download}
                                variant={Button.variant.primary}
                                onClick={(ev: any) => generatePriceListXlsx(ev)}
                                isDisabled={disableGenerationUntilUpdate || generationInProgress}
                            >
                                {(pricelistCustomer?.data?.pricelist_generated_at
                                    || xslxGenerationTimeout
                                    || pricelistCustomer?.data?.pricelist_generation_date)
                                    ? (t("Rigenera XLS")) : t("Genera XLS")}
                            </Button>
                        </div>

                        {
                            generationInProgress &&
                            <div>{t("Creazione file in corso")}...</div>
                        }

                        {pricelistCustomer?.data?.pricelist_generated_at && !xslxGenerationTimeout && !generationInProgress &&
                            <>
                                <div
                                    className={"flex gap-4 items-center justify-end mt-4"}>
                                    <Button
                                        icon={Button.icon.download}
                                        variant={Button.variant.primary}
                                        onClick={(ev: any) => downloadPriceListXlsx(ev)}
                                        isDisabled={(!pricelistCustomer?.data?.pricelist_download_path || pricelistCustomer?.data?.pricelist_download_path.length <= 0)}
                                    >
                                        {t("Scarica XLS")}
                                    </Button>
                                </div>

                                {pricelistCustomer?.data?.pricelist_generation_date &&
                                    <div>{t("Data listino prezzi: {{date}}", { date: formatDate(pricelistCustomer?.data?.pricelist_generation_date, "dd/MM/yyyy") })}</div>
                                }
                            </>
                        }
                    </div>
                </>
            }

            {
                (priceList?.data?.data?.length <= 0 || priceList?.data?.length <= 0) && (priceList?.customer ? priceList?.customer?.processing_tables?.length <= 0 : true) &&
                <div
                    className="h-96 flex flex-col items-center justify-center space-y-6">
                    <span
                        className="text-9xl text-gray-200"><ImFilesEmpty /></span>
                    <p className="text-2xl text-gray-600">{t("Nessun prodotto trovato")}</p>
                </div>
            }

            {
                priceList?.customer?.processing_tables?.length > 0 &&
                <fieldset
                    className="p-6 border space-y-6">
                    <div
                        className="uppercase mb-2 text-xl leading-6 font-semibold">{t("Attenzione")}</div>
                    <div
                        className="font-light">{t("Le modifiche appena apportate alla tabella  sconti sono in fase di elaborazione. Saranno completamente riflettute nel Listino Prezzi entro i prossimi 10 minuti. Ti invitiamo ad attendere questo breve intervallo prima di poter effettuare una ricerca o il download del listino.")}</div>
                </fieldset>
            }
        </>
    );
};
